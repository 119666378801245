import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Touring from "../../components/schedule/touring";
import PageContainer from "../../components/PageContainer";
import { TouringDetails } from "../../components/touring-intro";

const Burnside = () => (
    <Layout>
        <SEO title="KOFFIA Burnside" />
        <Touring city="Burnside" />
        <PageContainer>
            <TouringDetails />
        </PageContainer>
    </Layout>
);

export default Burnside;
